@import "../../../../styles/Variables.scss";


.remote-link {
	display: inline-flex;
	visibility: hidden;
	align-items: center;
	margin-left: 10px;
	margin-bottom: 7px;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
	
	i {
		font-size: 17px;
		color: $colour-primary;
		margin-right: 4px;
	}
}

.remote-ids-section-container {
	&:hover {
		.remote-link {
			visibility: visible;
			overflow: hidden;
		}
	}

	.sa-warning-icon {
		font-size: 18px !important;
	}

	.add-new-field-button {
		margin: 0;
	}
}

.remote-ids-container {
	background-color: transparent !important;
	display: inline-flex !important;
	justify-content: flex-start !important;
	flex-wrap: wrap !important;
	padding: 0 !important;
	width: 100%;
	gap: 8px;
	flex-wrap: wrap;

	.remote-id-wrapper {
		border-radius: 4px;
		padding: 3px 6px;
		background-color: $colour-secondary;
		display: flex;
		align-items: center;

		&:last-of-type {
			margin-right: 0;
		}

		.editable-remote{
			font-size: 18px;
			margin-right: 5px;
		}
		.primary{
			color: #F7A43D;
			cursor: default;
		}
		.not-primary{
			color: rgba(#fff, 0.5);
			cursor: pointer;
			&:hover{
				color: rgba( #F7A43D, 0.8);
			}
		}
	}

	.remote-name, .remote-id {
		font-weight: 400;
	}

	.remote-name {
		border-right: 1.5px solid rgba(#fff, 0.4);
		padding-right: 8px;
		margin-right: 8px;
		font-weight: 600;
	}
}