@import "../../../styles/Variables.scss";

.add-new-field-button {
	margin: 23px 0;
	align-items: center;
	justify-content: center;
	border: 1px dashed #8693C1;
	display: flex;
	color: rgba(#8693C1, 0.65);
	background-color: rgba(#58648E, 0.1);
	padding: 7px;
	border-radius: 6px;
	opacity: 0.5;
	transition: 200ms ease;
	cursor: pointer;
	width: 100%;
	max-height: 36px;

	&:hover {
		opacity: 1;

	}

	i {
		color: $colour-primary;
		margin-left: 6px;
	}
}