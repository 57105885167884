@import "../../../styles/Variables.scss";

.person-token-container {
	display: flex;
	height: 25px;
	align-items: center;
	border-radius: 5px;
	background-color: $colour-primary;
	font-size: 16px;
	padding: 0 7px;

	// &.secondary-token {
	// 	background-color: #9898CB;
	// }

	&.neutral-token {
		background-color: #30395a;
	}

	i {
		// margin-right: 4px;
		color: #fff;
		font-size: 17px;
	}

	&.clickable-token {
		cursor:pointer;
		&:hover {
			background-color: #4F597E;
		}
	}
}