.loading-icon-centered {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 30px;
}

.loading-header-container {
	margin-top: 10px;
}

.loading-header-container, .loading-message-container {
	width: 100%;
	text-align: center;
}