@import "../../../../styles/Variables.scss";


.remote-link {
	display: inline-flex;
	visibility: hidden;
	align-items: center;
	margin-left: 10px;
	margin-bottom: 7px;

	i {
		font-size: 17px;
		color: $colour-primary;
		margin-right: 4px;
	}
}

.remote-id-wrapper {
	border-radius: 4px;
	margin-right: 7px;
	margin-bottom: 7px;
	padding: 3px 6px;
	background-color: $colour-secondary;
	display: flex;
	align-items: center;

	&:last-of-type {
		margin-right: 0;
	}

	.acc-primary-icon {
		color: rgba(#fff, 0.4);
		font-size: 18px;
		margin-right: 5px;

		&.is-primary{
			color: #F7A43D;
			cursor: default;
		}
	}
}

.remote-name, .remote-id {
	font-weight: 400;
}

.remote-name {
	border-right: 1.5px solid rgba(#fff, 0.4);
	padding-right: 8px;
	margin-right: 8px;
	font-weight: 600;
}