@import "../../../../styles//Variables.scss";

.overseas-toggle-wrapper {
	padding: 12px 0;
}

.overseas-fields-container {
	.single-field-wrapper {
		&:last-of-type {
			padding-bottom: 0 !important;
		}
	}

	.form-col-grow {
		padding: 0;
	}

	.form-row {
		.form-col-grow {
			&:last-child {
				padding-left: 8px;
			}

			&:first-child {
				padding-right: 8px;
			}

			&:only-child {
				padding: 0;
			}
		}
	}
}