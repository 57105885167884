$layer-0: 0;
$layer-1: 10;
$layer-2: 20;
$layer-3: 30;
$layer-4: 40;
$layer-5: 50;
$layer-6: 60;
$layer-7: 70;
$layer-8: 80;
$layer-9: 90;
$layer-10: 100;