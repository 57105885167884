@import "../../../../styles/Variables.scss";

.addable-phone-wrapper {
	background-color: $colour-form-input;
	border-radius: 6px;
	transition: 200ms;
	padding: 3px 8px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.single-field-wrapper {
		padding: 8.5px 0;
		border-bottom: 1px solid rgba(#fff, 0.3);

		&:first-of-type {
			width: 100%;
			padding: 6px 0;
		}

		&:nth-of-type(2) {
			width: 30px;
			border-bottom: none;
		}

		&:last-of-type {
			width: auto;
			flex-grow: 1;
			border-bottom: none;
		}
		
		input {	
			background-color: transparent !important;
			border: none;
			margin: 0;
			padding: 0 2px;
		}

		.input-validation-text-invalid {
			border: none !important;
		}

		.input-validation-text-invalid, .input-validation-text, .extension-input {
			height: 25px !important;
			border-left: 1px solid rgba(#fff, 0.3) !important;
			padding-left: 10px;
			margin-top: 0 !important;
		}

		.prefix-input {
			width: 70px;
		}

		.input-validation-warning-message {
			font-size: 13px;
			margin-top: 5px;
		}

		.input-validation-warning-icon {
			margin-right: 5px;
		}

		.token-field {
			margin: 0;
		}

		.token-field__control {
			min-height: auto;
		}

		.token-field__value-container {
			padding: 0 !important;

			.suggestion-text {
				color: #fff;
			}
		}

		.token-field__indicator {
			padding: 0 4px !important;
		}

		.token-field .token-field__control {
			background-color: transparent !important;
			color: #fff !important;	
			border: none !important;
		}

		.token-field__value-container--has-value {
			color: #fff;
		}

		.token-field__placeholder {
			color: #fff !important;
			opacity: 0.2;
		}

		.token-field__control--is-focused {
			.token-field__placeholder {
				color: rgba($colour-form-focus-text, 1) !important;
			}
		}
	}

	.field-horizontal-divider {
		width: 1px;
		height: 26px;
		margin: 0 8px;
		background-color: rgba(#fff, 0.3);
	}

	&:focus-within {
		background-color: #fff;

		.dropdown-value-wrapper, .dropdown-value-wrapper i {
			color: $colour-form-focus-text !important;

			&:hover {
				color: $colour-form-focus-text;
			}
		}

		.dropdown-selection-list {
			color: $colour-form-focus-text;
			background-color: #fff;
			box-shadow: 0px 6px 12px rgba(#000, 0.2);
			border: 1px solid rgba($colour-secondary, 0.06);

			li {
				color: $colour-primary;
				border-left: rgba($colour-secondary, 0.06);
				border-right: rgba($colour-secondary, 0.06);

				&:hover {
					background-color: rgba($colour-secondary, 0.06) !important;

				}
			}
		}

		.field-horizontal-divider {
			background-color: rgba(#30395A, 0.2);
		}

		.token-field__value-container--has-value {
			color: $colour-form-focus-text !important;

			.suggestion-text {
				color: $colour-form-focus-text;
			}
		}

		.token-field__placeholder {
			color: rgba($colour-form-focus-text, 1) !important;
		}

		input {
			color: $colour-form-focus-text !important;
			
			&::placeholder {
				color: $colour-form-focus-text !important;
			}
		}

		.single-field-wrapper {
			border-color: rgba(#30395A, 0.2);

			&:last-of-type {
				border-bottom: none
			}
		}

		.token-field__single-value {
			color: $colour-form-focus-text !important;
		}

		.input-validation-text-invalid, .input-validation-text, .extension-input {
			border-color: rgba(#30395A, 0.2) !important;
		}

		.input-validation-warning-message {
			color: $colour-form-focus-text;
		}
	}
}