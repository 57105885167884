@import "../../../../styles/Variables.scss";

.checkbox-date-wrapper {
	display: flex;
	align-items: center;

	.checkbox {
		margin-bottom: 0;
		border-right: 1.5px solid rgba(#9898CB, 0.3);
		padding-right: 7px;
		margin-right: 10px;
	}

	.checkbox-field {
		display: flex;
		align-items: center;
	}

	span {
		display: flex;
		align-items: center;
	}

	i {
		color: $colour-primary;
		font-size: 16px;
		margin-right: 5px;
	}
}

.checkbox-date-edit {
	display: flex;
	align-items: center;
	margin: 9px 0;

	.checkbox-field-wrapper {
		padding: 7px 0;
		padding-left: 10px;
		display: flex;
		align-items: center;
		border-left: 1.5px solid rgba(#9898CB, 0.3);
	}

	.checkbox-field {	
		&:hover {
			i {
				opacity: 0.6;
	
				&:before {
					opacity: 0.6;
				}
			}
		}
	}

	.date-field-outer {
		width: 190px;
		margin-right: 10px;
	}

	.checkbox {
		border-right: 1.5px solid rgba(#9898CB, 0.3);
		padding: 6px 0;
		padding-right: 11px;
		margin: 0;
		margin-right: 20px;
	}

}