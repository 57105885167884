.split-field-input {
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: normal;
	width: 100%;
	padding: 17px 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 14px;
	font-size: 15px;
	line-height: 15px;
	background-color: #d3d3d3;

	.sfi-value {
		padding-right: 14px;
		border-right: 1px solid #B5B5B5;

		&:last-of-type {
			border: none;
			padding-right: 0;
		}
	}

	.sfi-icon {
		margin-left: auto;
		cursor: pointer;
	}

	.sf-outer {
		margin-bottom: 0;
		width: 100%;
	}

}