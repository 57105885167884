@import "@threeskye/core-components/dist/styles/Variables.scss";
// VARIABLES

// SIZING
$button-font-size: 1rem;
$button-icon-font-size: 1.3rem;
$button-padding: 0 16px;
$button-width: 80px;
$button-height: 36px;

// BORDER
$button-border-width: 2px;
$button-border-radius: 5px;

// COLOURS
$button-bg-colour: $colour-button-bg;
$button-text-color: #fff;
$button-text-color-outlined: $colour-primary;
$button-border-colour: $colour-button-bg;

// MIXINS
@mixin buttonColours($button-colour, $icon-colour) {
	background-color: $button-colour;
	border-color: $button-colour;

	&.button-outlined {
		background-color: transparent;

		i {
			color: $button-colour !important;
		}

		&:hover {
			opacity: 1;
			background-color: $button-colour;

			i {
				color: $button-text-color !important;
			}
		}
	}

	&.button-transparent {
		background-color: transparent;
		border-color: transparent;
		opacity: 1 !important;

		i {
			color: $button-colour !important;
		}
		

		&:hover {
			background-color: $button-colour;

			i {
				color: $button-text-color !important;
			}
		}
	}

	&.button-alternate {
		&:hover {
			background-color: rgba($button-colour, 0.2);
			border-color: $button-colour;
			opacity: 1;
		}
	}
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––

// CLASSES
button,
.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@include buttonColours($button-bg-colour, $button-text-color);
	font-size: $button-font-size;
	font-family: $font-primary;
	color: $button-text-color;
	padding: $button-padding;
	border-style: solid !important;
	border-width: $button-border-width !important;
	border-radius: $button-border-radius;
	height: $button-height;
	min-width: $button-width;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	transition: ease-out 200ms;

	&:hover {
		opacity: 0.5;
	}

	a {
		&:hover {
			opacity: 1 !important;
			color: inherit;
		}
	}

	&:disabled,
	&.button-disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	&:active {
		outline: none;
		border-style: solid;
	}

	&.button-rounded {
		border-radius: 100px;
	}

	&.button-with-icon {
		i {
			font-size: $button-icon-font-size;
			margin-right: 10px;
			color: $button-text-color;
		}
	}

	&.button-no-text {
		i {
			margin-right: 0 !important;
		}
	}

	&.button-small {
		font-size: $button-font-size * 0.8;
		min-width: $button-width * 0.8;
		height: $button-height * 0.8;

		i {
			font-size: 0.9 * $button-icon-font-size;
		}
	}

	&.button-large {
		font-size: $button-font-size * 1.3;
		min-width: $button-width * 1.2;
		height: $button-height * 1.2;
	}

	&.button-loading {
		pointer-events: none;

		svg {
			margin-right: 10px;
		}

		.threeskye-loading-icon-outer-ring, .threeskye-loading-icon-inner-ring {
			fill: #fff !important;
		}
	}

	@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-md) {
		&.button-mobile-small {
			font-size: $button-font-size * 0.8;
			min-width: $button-width * 0.8;
			height: $button-height * 0.8;

			i {
				font-size: 0.9 * $button-icon-font-size;
			}
		}

		&.button-mobile-large {
			font-size: $button-font-size * 1.3;
			min-width: $button-width * 1.2;
			height: $button-height * 1.2;
		}
	}

	@media (min-width: $breakpoint-lg) {
		&.button-desktop-small {
			font-size: $button-font-size * 0.8;
			min-width: $button-width * 0.8;
			height: $button-height * 0.8;

			i {
				font-size: 0.9 * $button-icon-font-size;
			}
		}

		&.button-desktop-large {
			font-size: $button-font-size * 1.3;
			min-width: $button-width * 1.2;
			height: $button-height * 1.2;
		}
	}

	&.button-heavy {
		font-weight: bolder;
	}

	&.button-light {
		font-weight: lighter;
	}

	&.button-secondary {
		@include buttonColours($colour-secondary, $colour-secondary);
	}

	&.button-warning {
		@include buttonColours($colour-warning, $colour-warning);
	}

	&.button-success {
		@include buttonColours($colour-success, $colour-success);
	}

	&.button-full-width {
		width: 100% !important;
	}

}

.button-container {
	display: flex;
	justify-content: space-between;

	&.split-buttons {
		button{
			width: 49%;
		}
	}
}
