@import "../../../../styles/Variables.scss";

.rr-ar-header {
	display: flex;
	align-items: center;
	font-size: 1rem;
	margin-bottom: 7px;
}

.details-item-multi-field-outer {
	display: inline-block;
	margin-right: 10px;

	label {
		opacity: 0.3;
		font-size: 14px;
	}
}

.details-item-multi-field-wrapper {
	display: flex;
	align-items: center;
}

.details-item-multi-field-wrapper-flow {
	display: flow-root;
}

.multi-field-view-value {
	margin-right: 4px;

}

.mini-labels {
	display: inline-flex;
	flex-direction: column;
	flex-wrap: wrap;

	.mini-label {
		font-size: 11.5px;
		opacity: 0.6;
		margin-bottom: 4px;
	}

	.mini-label-span-value {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.flow-item {
	line-height: 2rem;
}

.divided-value-field-item {
	margin-right: 9px;
	padding-right: 9px;
	border-right: 1.5px solid rgba(#9898CB, 0.3);

	&:last-of-type {
		border-right: none;
		margin-right: 0;
	}
}

.input-checkbox {
	display: flex;
	align-items: center;
	margin-top: 19px;

	input {
		width: auto;
		margin: 0;
		margin-right: 8px;
	}
}


.details-item-multi-field-editor {
	background-color: $colour-form-input;
	color: $colour-form-text;
	font-size: 14px;
	color: #fff;
    margin: 0.4rem 0;
    background-color: #30395a;
    border-radius: 5px;
    padding: 0.6em 0.8rem;
    outline: none;

	&:focus {
		background-color: $colour-form-focus;
		color: $colour-form-focus-text;
	}
}

.review-button-small {
	display: inline-flex;
	align-items: center;

	i {
		color: $colour-primary;
	}
}

.review-button-large {
	display: none;
}

// @media screen and (min-width: 1300px){
.review-button-small {
	display: none;
}

.review-button-large {
	display: block;
}

// }