@import "../../../../styles/Variables.scss";

.details-checkbox-resting {
	.checkbox-field {
		display: flex;
		align-items: center;
		font-size: 16px;

		i {
			color: $colour-primary;
			margin-right: 5px;
			transition: 200ms ease;
			position: relative;
			font-size: inherit;

			// &:before {
			// 	content: "";
			// 	position: absolute;
			// 	left: 0;
			// 	right: 0;
			// 	top: 0;
			// 	bottom: 0;
			// 	background-color: $colour-primary;
			// 	transform: scale(0.7);
			// 	opacity: 0;
			// 	transition: 200ms ease;
			// }
		}

		&:hover {
			i {
				opacity: 0.6;

				&:before {
					opacity: 0.6;
				}
			}
		}
	}
}

.checkbox-field {
	display: flex;
	align-items: center;
	font-size: 16px; 
}

.bool-checkbox-edit {
	margin-right: 5px;
	position: relative;
	font-size: 1.2rem;
    color: #208BA5 !important;
}