@import "../../../../styles/Variables.scss";

.multi-column-container {
	display: flex;
	padding: 0;
}

// Need to add padding right 0 to the last column
.details-item-multi-column-outer {
	flex: 1 1;
	background-color: transparent !important;
	padding-right: 15px;
	
	&:last-of-type {
		padding-right: 0;
	}

	label {
		font-size: 1rem;
	}
}