@import "../../../styles/Variables.scss";

$icon-margin: 5px;

@mixin tagLabel {
	font-size: 0.8rem;
	opacity: 0.7;
	margin-left: 0.5rem;
}

.token-field__menu-notice--no-options {
    background-color: rgba($colour-primary, 0.2);
    
    .create-suggestion {
        color: $colour-primary;
    }
}

//for suggestions outside of modal
.token-field-suggestions {
	display: flex;
	flex-wrap: wrap;
    margin: 16px 0 0 0;
    
	.suggestion-label-container{
        margin: 0 0 0.5rem 0;
        width: 100%;
        
		.suggestion-label{
			margin-right:10px
		}
    }
    
	&:focus {
		.token-suggestion-container {
       	 	.suggestion {
				color: $colour-primary;
            }
        }
    }

	.token-suggestion-container {
		padding: 0.3rem;
		margin: 0.12rem;
		font-size: 0.9rem;
		background-color: rgba($colour-primary, 0.5);
    	color: $colour-text;
    	border: 1px solid $colour-primary;
    	color: #fff;
		overflow: hidden;
		
		.suggestion {
            transition: ease-in-out 200ms;

			i {
				margin-top: 0.3rem;
                font-size: 0.7rem;
                margin-right: $icon-margin;
            }
            
			cursor: pointer;
			padding: 0.4rem;
			box-sizing: border-box;
			
        }
        
		&:hover {
			opacity: 1;
			background-color: rgba($colour-primary, 0.8);
		}
    }
}

// Event modal tagged accounts & clients container
.client-and-account-tag-field{
    .token-field__value-container {
        padding: 7px 9px;
    }
    
    .token-field__value-container--has-value {
        padding: 5px 7px;
    }

    .token-field__placeholder {
        margin-left: 0 !important;
    }

	i {
        font-size: 0.7rem;
        margin-right: $icon-margin;
	}
}

.token-field {
    font-size: 0.9rem;

    @media screen and (max-width: $breakpoint-md) {
        font-size: 1rem;
    }
    margin: 0.4rem 0;

    &.cat-token-field {
        .token-field__multi-value {
            background-color: #3d4567;
            color: $colour-text;
            margin: 0;
            margin-right: 4px;
            margin-top: 2px;
            margin-bottom: 2px;
            color: #fff;
            overflow: hidden;
            position: relative;
            border-radius: 4px;
            gap: 4px;

            &:hover {
                background-color: lighten(#3d4567, 8%);
            }
    
            .ca-token-small {
                padding: 0;
                margin: 0;
                background-color: transparent;
                width: 100%;
            }
        }

        // Tagged accounts & clients remove button
        .token-field__multi-value__remove {
            margin: 0;
            background-color: transparent;
            padding-left: 0;
            // opacity: 0.7;
            // position: absolute;
            // right: 8px;
            // top: 50%;
            // transform: translateY(-50%);
            // padding: 0;
            // border-radius: 4px;
        }
    }

    .token-field__multi-value {
        background-color: $colour-primary;
    }

    .token-field__multi-value__label {
        height: 24px;
        display: flex;
        align-items: center;
    }

    .token-field__multi-value__remove {
        position: relative;
        top: auto;
        transform: none;
        padding-left: 12px;
        background-color: transparent;

        .select-clear-icon {
            opacity: 0.8;
            font-size: 16px !important;
            margin: 0;
        }
        
        &:hover {
            background-color: transparent;
            color: #fff;
            opacity: 0.3;
            cursor: pointer;

            .select-clear-icon {
                opacity: 1;
            }
        }
    }
	
	&:focus {
        .suggestion {
            .token-field__multi-value {
                color: $colour-primary;
            }
        }
    }

    .suggestion {
        .token-field__multi-value {
            cursor: pointer;
            opacity: 0.5;
            background-color: rgba($colour-primary, 0.5);
            box-sizing: border-box;
            transition: ease-in-out 200ms;

            &:hover {
                opacity: 1;
                background-color: rgba($colour-primary, 0.8);
            }
        }
    }
    // Tagged accounts & clients text
    .token-field__multi-value__label {
        color: #fff;
		font-size: 0.9rem;
        transform: translateY(1px);
        
        i {
            margin-right: $icon-margin;
        }
		
    }

    // Accounts & clients Input field container
    .token-field__control {
        background-color: $colour-secondary;
        border: none;
        // margin-top: 0.3rem;

        &.token-field__control--is-focused {
            background-color: #fff;

            .select-clear-icon {
                color: $colour-form-focus-text;
            }

            // Colour of text when field is filled and not focused
            .css-xp4uvy {
                color: $colour-form-focus-text;
            }
        }
    }

    .token-field__control--is-focused {
        box-shadow: none;
        border: none;
        margin-top: 0.3rem;
        
        .token-field__placeholder {
            color: $colour-form-focus-text;
        }

        .token-field__single-value {
            color: $colour-form-focus-text !important;
        }

        .select-clear-icon {
            color: $colour-primary;
        }

        .token-field__multi-value__remove {
            .select-clear-icon {
                color: #fff;
            }
        }
    }

    // Dropdown autocomplete menu for tagging accounts & clients
    .token-field__menu {
        background-color: $colour-text;
        color: $colour-primary;
        border-radius: 6px;

        .account-number, .client-email {
            @include tagLabel();
        }

        .client-token-option, .npe-token-option, .account-token-option {
            display: flex;
            align-items: center;
            i {
                font-size: 14.5px;
            }
        }

        .client-token-option {
            color: $colour-client;
        }

        .npe-token-option {
            color: $colour-npe;
        }

        .account-token-option {
            color: $colour-account;
        }
	}
	
}

.token-field__input {
    input {
        margin: 0 !important;
    }
}

.token-field__placeholder {
    color: #fff !important;
    opacity: 0.2;
}

.token-field__control--is-focused{
    .token-field__placeholder {
        color: #1a2038 !important;
        opacity: 0.2;
    }
}



// Background colour fix for the list of results if the field is filled already.
.token-field__option {
    background-color: transparent !important;
}

.token-field__option--is-focused {
    background-color: rgba($colour-secondary, 0.06) !important;
}

// Colour of text when field is filled and not focused
.token-field__single-value {
    color: #fff !important;
}

.details-modal-input-section {
    .css-10nd86i.token-field {
        font-size: 0.8rem;
    }
}

.token-selection-sub {
	margin-left: 0.4em !important;
	font-size: 0.85em !important;
	opacity: 0.6 !important;
    margin-right: 3px;
}

.account-token-option, .client-token-option, .npe-token-option {
    &.email-option {
        display: inline-flex;
    
        .token-selection-sub {
            margin: 0 !important;
        }
    }
}

.token-field__option--is-selected {
    color: $colour-primary !important;
}

.token-field__menu-list {
    .prefix-suggestion {
        .prefix-value {
            margin-left: 6px;
            opacity: 0.7;
        }
    }
} 

.token-field-suggestions-popup {
   
    &.popup-element-outer {
        z-index: 2000;
    }
}

.account-type {
    font-weight: bold;
}

.token-no-icon {
    height: 24px;
    display: flex;
    align-items: center;
    padding-left: 6px;
}