.single-phone-edit {
	.input-validation-prefix {
		margin-top: 6.4px;
	}

	input {
		margin-top: 0;
	}

	.input-validation-warning-message {
		margin-top: 2px;
	}
}