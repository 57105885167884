// @import "../styles/Colours.scss";
// @import "../../themes/themes.scss";

.bank-account-field-wrapper {
	.form-label {
		text-transform: uppercase;
		font-weight: bold;
		line-height: 2em;
		letter-spacing: 1px;
		font-size: 14px;
		margin-bottom: 10px;
		
		// @include themify($themes) {
		// 	color: themed('input-label-colour');
		// }
	}

	.bank-acc-inner-wrapper {
		width: 100%;
	}

	.portal-label {
		margin-bottom: 0;
	}
}