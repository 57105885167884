@import "../../../../styles/Variables.scss";

.advisers-container {
	background-color: transparent !important;
	display: flex !important;
	justify-content: flex-start !important;
	flex-wrap: wrap !important;
	padding: 0 !important;

	.person-token-container {
		margin-right: 7px;
		margin-bottom: 7px;

		&:last-of-type {
			margin-right: 0;
		}
	}
}