@import "../../../../styles/Variables.scss";

.radio-input-wrapper {
	width: 100%;
}

input[type="radio"] {
	width: auto;
	margin-right: 10px;
}

.switch-input-container {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.switch {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 20.5px;
		margin-right: 10px;
		margin-bottom: 0 !important;
	}
	  
	.switch input { 
		opacity: 0;
		width: 0;
		height: 0;
	}
	  
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 3px;
		background-color: #404863;
		-webkit-transition: .4s;
		transition: 0.4s;
		
		&.colours-off-on {
			background-color: #3d4358;
		}

		&.colours-toggle-between {
			background-color: $colour-primary;
		}
	}
	  
	.slider:before {
		position: absolute;
		content: "";
		height: 15px;
		width: 15px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}
	  
	input:checked + .slider {
		background-color: $colour-primary;
	}

	input:checked + .slider {
		&.colours-off-on {
			background-color: $colour-success;
		}

		&.colours-toggle-between {
			background-color: $colour-primary;
		}
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(18px);
		-ms-transform: translateX(18px);
		transform: translateX(18px);
	}
	
	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}
	  
	.slider.round:before {
		border-radius: 50%;
	}
}

.toggle-label {
	transition: 200ms ease;
	cursor: pointer;
	
	&.toggle-label__inactive {
		opacity: 0.25;
		color: #fff;
	}
	
	&.toggle-label__active {
		opacity: 1;
		color: #fff;
	}
}