@import "@threeskye/core-components/dist/styles/Variables.scss";

// Move to core components
@mixin inputResetStyle {
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent;
    border: none !important;
}

.date-field-outer {
    font-size: 0.9rem;
	display: flex;
    align-items: center;
	border-radius: 5px;
    width: 100%;
    padding: 0.6em 0.8em;
    margin: 0.4rem 0;
    outline: none;
    height: $input-height;

    i {
        font-size: 1.2em;
        margin-right: 5px;
    }

    input {
        @include inputResetStyle();
        height: auto;
        text-align: center;

        &:nth-child(3) {
            width: 25px;
        }
        &:nth-child(4) {
            width: 25px;
        }
        &:nth-child(5) {
            width: 45px;
        }
    }
}


.calendar-trigger {
    cursor: pointer;
}

.calendar-wrapper {
    position: absolute;
    z-index: 10000;
    display: none;
}

.calendar-wrapper-showing {
    display: block;
    align-self: flex-end;
    transform: translateY(100%);
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    border: none !important;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 0 8px rgba(#000, 0.5);
    font-family: Arial,Helvetica,sans-serif;
    line-height: 1.125em;

    button {
        padding: 0.5em 1em !important;
        border-radius: 6px !important;
        border-width: 0 !important;
        font-size: 0.8em;
        min-width: auto !important;
        height: auto !important;
    }

    abbr {
        border-bottom: none !important;
        cursor: inherit !important;
        text-decoration: none !important;
    }

    .react-calendar__navigation {
        align-items: center;
        height: 34px;

            button {
                padding: 0.2em 0.1em !important;
            }
    }
}

button.react-calendar__navigation__label {
    font-size: 1.2em !important;
}

button.react-calendar__navigation__arrow {
    font-size: 1.5em !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
    padding: 0.8em !important;
}

// Styles for the date input fields
.date-field-field {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    font-size: 0.9rem !important;
}

.date-field-year {
    margin-left: 10px !important;
}

@media (min-width: 425px) {

    .date-time-input-container {
        .date-field-outer {
            width: 48% !important;
        }
    }
}
