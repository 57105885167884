.list-of-fields {
	.single-field {
		margin-bottom: 23px;
	}
}

.list-of-fields-view {
	width: 100%;
	font-size: 16px;
	
	.single-field {
		td {
			padding: 9px 10px;
		}
		.label {
			white-space: nowrap;
		}
		.value {
			background-color: rgba(48, 57, 90, 0.5);
			width: 100%;
			border-radius: 6px;
		}
	}
}

.list-of-fields-change {
	.details-container {
		margin-bottom: 10px;
	}
}