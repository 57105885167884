//*****************************
// DARK(DEFAULT) THEME
//*****************************

// GENERAL
$colour-background: #1a2038;
$colour-primary: #0d8efd;
$colour-secondary: #30395a;
$colour-client: #0d8efd;
$colour-npe: #30C9AC;
$colour-account: #7F64EA;
$colour-authorised: #28b4bf;
$colour-third-party: #EB9D74;

// BUTTONS
$colour-button-bg: $colour-primary;
$colour-button-text: #fff;

// TYPOGRAPHY
$colour-text: #fff;
$colour-link-primary: $colour-primary;
$colour-link-alt: rgba($colour-text, 0.3);
$colour-link-primary-highlight: $colour-primary;
$colour-link-alt-highlight: $colour-primary;

// FORMS
$colour-form-text: #fff;
$colour-form-input: #30395a;
$colour-form-focus: #fff;
$colour-form-focus-text: #1a2038;

// STATES
$colour-success: #30c394;
// $colour-warning: #ed443d; Original red. Will keep here just in case...
$colour-warning: #f7a43d;

// SIDEBAR
$colour-sidebar: #1a2038;
$colour-sidebar-events: #30395A;


// MISC
$colour-overlay: #101423;
$colour-portfolio-bar: #475073;

//*****************************
// LIGHT THEME
//*****************************

// GENERAL
$light-colour-background: rgb(255, 255, 255);
$light-colour-primary: #0d8efd;
$light-colour-secondary: #dcdcdc;


// BUTTONS
$light-colour-button-bg: $light-colour-primary;
$light-colour-button-text: #fff;

// TYPOGRAPHY
$light-colour-text: #1a2038;
$light-colour-link-primary: $light-colour-primary;
$light-colour-link-alt: rgba($light-colour-text, 0.3);
$light-colour-link-primary-highlight: $light-colour-primary;
$light-colour-link-alt-highlight: $light-colour-primary;

// FORMS
$light-colour-form-text: #30395a;
$light-colour-form-input: #f7f7f7;
$light-colour-form-focus: #30395a;
$light-colour-form-focus-text: #fff;

// STATES
$colour-success: #30c394;
$colour-warning: #f7a43d;

// ACTIONS
$light-colour-hover-background: #e9e9e9;

// SIDEBAR
$light-colour-sidebar: #1a2038;
$light-colour-sidebar-events: #30395A;

// MISC
$light-colour-overlay: #101423;
$light-colour-container: #fff;
$light-colour-portfolio-bar: #475073;