@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Thin.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Thin.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Thin.eot") format("eot");
  font-weight: 100; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-ExtraLight.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-ExtraLight.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-ExtraLight.eot") format("eot");
  font-weight: 200; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Light.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Light.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Light.eot") format("eot");
  font-weight: 300; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Regular.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Regular.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Regular.eot") format("eot");
  font-weight: 400; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Medium.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Medium.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Medium.eot") format("eot");
  font-weight: 500; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-SemiBold.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-SemiBold.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-SemiBold.eot") format("eot");
  font-weight: 600; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Bold.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Bold.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Bold.eot") format("eot");
  font-weight: 700; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-ExtraBold.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-ExtraBold.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-ExtraBold.eot") format("eot");
  font-weight: 800; }

@font-face {
  font-family: "Metropolis";
  src: url("~@threeskye/core-components/dist/resources/webfonts/WOFF2/Metropolis-Black.woff2") format("woff2"), url("~@threeskye/core-components/dist/resources/webfonts/WOFF/Metropolis-Black.woff") format("woff"), url("~@threeskye/core-components/dist/resources/webfonts/EOT/Metropolis-Black.eot") format("eot");
  font-weight: 900; }

$font-primary:  "Metropolis", sans-serif !important;
