@import "@threeskye/core-components/dist/styles/Variables.scss";

$colour-tooltip: $colour-secondary;

.tooltip-container {
	position: relative;
	z-index: $layer-8;
	font-weight: 400;
	width: auto;
	display: inline-flex;
	
	i {
		font-size: 0.9em;
		cursor: pointer;
	}

	&:hover {
		.tooltip-popup {
			transition: 0s display;
			display: block !important;
		}
	}

	&.primary {
		.tooltip-popup {
			background-color: $colour-primary !important;

			&::after {
				border-top-color: $colour-primary !important;
			}
		}
	}

	&.lighten {
		.tooltip-popup {
			background-color: lighten($colour-secondary, 10%) !important;

			&::after {
				border-top-color: lighten($colour-secondary, 10%) !important;
			}
		}

		&.left-center {
			.tooltip-popup {
				&:after {
					border-left-color: lighten($colour-secondary, 10%) !important;
					border-top-color: transparent !important;
				}
			}
		}

		&.right-center {
			.tooltip-popup {
				&:after {
					border-right-color: lighten($colour-secondary, 10%) !important;
					border-top-color: transparent !important;
				}
			}
		}
	}

	&.warning-tooltip {
		i {
			color: $colour-warning !important;
		}
	}

	&.compact {
		.tooltip-popup {
			font-size: 15px;
			padding: 9px;
			top: -39px;
			max-width: 200px;
			white-space: break-spaces;

			// &::after {
			// 	display: none;
			// }
		}
	}

	&.left-aligned {
		.tooltip-popup {
			left: 0;
			top: -46px;
			transform: translateX(0) translateY(0);
			white-space: normal;

			&::after {
				display: none;
			}
		}
	}

	&.left-center {
		.tooltip-popup {
			top: 50%;
			left: -10px;
			transform: translateY(-50%) translateX(-100%);
			white-space: normal;
        	min-width: 170px;

			&::after {
				display: block;
				top: 50%;
				left: auto;
				right: -14px;
				transform: translateY(-50%);
				border-bottom: 8px solid transparent;
				border-top: 8px solid transparent;
				border-left: 9px solid $colour-tooltip;
			}
		}
	}

	&.right-center {
		.tooltip-popup {
			top: 50%;
			right: 0;
			left: 0;
			transform: translateY(-50%) translateX(27px);
			white-space: normal;
        	min-width: 170px;

			&::after {
				display: block;
				top: 50%;
				right: auto;
				left: -14px;
				transform: translateY(-50%);
				border-bottom: 8px solid transparent;
				border-top: 8px solid transparent;
				border-right: 9px solid $colour-tooltip;
			}
		}
	}

	&.right-aligned {
		.tooltip-popup {
			top: -46px;
			right: 0;
			  transform: translateX(-94%);
				white-space: normal;

			&::after {
				display: none;
			}
		}
	}

	&.sidebar-link {
		width: 100%;

		&:hover {
			.tooltip-popup {
				opacity: 1;
				transition-delay: 750ms;
				visibility: visible;
				left: 50%;
			}
		}

		.tooltip-popup {
			font-size: 15px;
       		min-width: auto;
			left: 46%;
			display: block !important;
			visibility: hidden;
			opacity: 0;  
			transition: opacity 100ms, left 200ms;
		}
	}

	&.word-wrap {
		.tooltip-popup {
			top: -50px;
			white-space: normal;
		}
	}
}

.tooltip-popup, .detail-popup {
	display: none !important;
	position: absolute;
	top: -50px;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	white-space: nowrap;
	padding: 10px 14px;
	border-radius: 6px;
	background-color: $colour-tooltip;
	text-align: center;
	z-index: $layer-2;
	box-shadow: 0 2px 4px rgba(#000, 0.2);
	color: #fff;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -5px;
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid $colour-tooltip;
	}
}

.tooltip-disabled {
	pointer-events: none;
	opacity: 0.4;
}